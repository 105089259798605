'use client';

import Link from 'next/link';
import { getLocalStorage, setLocalStorage } from '@/lib/storageHelper';
import { useState, useEffect } from 'react';

export default function CookieBanner() {
  const [cookieConsent, setCookieConsent] = useState<boolean | null>(null);

  useEffect(() => {
    const storedCookieConsent = getLocalStorage('cookie_consent', null);

    setCookieConsent(storedCookieConsent);
  }, [setCookieConsent]);

  useEffect(() => {
    const newValue = cookieConsent ? 'granted' : 'denied';

    window.gtag('consent', 'update', {
      analytics_storage: newValue,
    });

    setLocalStorage('cookie_consent', cookieConsent);
  }, [cookieConsent]);
  return (
    <div
      className={`fixed bottom-0 left-0 right-0
                  z-50 mx-auto my-20 flex max-w-max
                  flex-col items-center justify-between gap-4 rounded-lg border-[1px] border-[#ff6f20] bg-white px-3 py-3  
                  shadow sm:flex-row md:max-w-screen-sm md:px-4
                  ${cookieConsent !== null ? 'hidden' : 'flex'}`}
    >
      <div className="text-white-200 text-center">
        <Link href="/terms-and-privacy/privacy">
          <p>
            We use <span className="font-bold text-[#ff6f20]">cookies</span> to
            enhance your experience.
          </p>
        </Link>
      </div>

      <div className="flex gap-2">
        <button
          className="rounded-md border-[#ff6f20] px-5 py-2 text-gray-500"
          onClick={() => setCookieConsent(false)}
        >
          Decline
        </button>
        <button
          className="text-white-200 rounded-lg bg-[#ff6f20] px-5 py-2"
          onClick={() => setCookieConsent(true)}
        >
          Allow Cookies
        </button>
      </div>
    </div>
  );
}
